import React, { Fragment } from 'react';

import Alert from 'react-bootstrap/lib/Alert';
import Button from 'react-bootstrap/lib/Button';

import AffiliationItem from '../authors/AffiliationItem';
import { constructAuthorPath } from '../authors/AuthorLink';
import { SuggestBox } from '../widgets/Suggest';
import type { SuggestsResponseType, SuggestType } from '../widgets/Suggest';
import type { RefereeType } from './Types';
import { toFixed, cx, checkStatus, singlePlural } from '../utils';
import LRUCache from '../LRUCache';
import { useSubscription, updateGlobalData, sendRequest } from '../datastore';
import helpers from './helpers';
import {
    makeCustomRefereesWithAdditionalDocKey, makeCustomRefereesKey,
    enrichReferees,
} from './RefereesData';


import './search-entity.css';


function AuthorSuggest(
    { isActive, isAdded, addedReferee, author, onClick }:
    {
        isActive: boolean,
        isAdded: boolean,
        addedReferee: ?RefereeType,
        author: SuggestType,
        onClick?: () => any
    }
) {
    return <li className={cx('article-suggest with-action', {'active': isActive})}>
        <div className="article-card custom-author-brief">
            <div className="brief">
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a target="_blank" className="article-title" href={constructAuthorPath(author.id, author.value)}>
                    {author.value}
                </a>
                {author.extra && <Fragment>
                    <span>h-index: {author.extra.hIndex}</span>
                    <span>
                        {author.extra.articlesCount}{' '}
                        {singlePlural('article', author.extra.articlesCount)}
                    </span>
                </Fragment>}
                {addedReferee && <Fragment>
                    <span>Score: {toFixed(addedReferee.score, 2)}</span>
                    <span>
                        Position: {addedReferee.position}
                    </span>
                </Fragment>}
            </div>
            {author.extra && <div className="details">
                {author.extra.affiliations && !!author.extra.affiliations.length && <ul>
                    {author.extra.affiliations.map(aff => <li key={aff.value}>
                        <AffiliationItem item={aff} />
                    </li>)}
                </ul>}
                {author.referee?.author?.datedAffiliations && !!author.referee.author.datedAffiliations.length && <ul>
                    {author.referee.author.datedAffiliations.map(aff => <li key={aff.value}>
                        <AffiliationItem item={aff} />
                    </li>)}
                </ul>}
            </div>}
        </div>
        <div className="action">
            {!isAdded ?
                <Button onClick={onClick}>Add</Button>
                : <a className={cx('btn btn-link', {'disabled': !addedReferee})}
                    href={`#author-${author.id}`}>
                    {addedReferee ? 'Details' : 'Ranking…'}
                </a>
            }
        </div>
    </li>;
}


function AddedAuthors({customAuthors, loading}) {
    return <div className="added search-entity results" style={{paddingTop: '5px'}}>
        <p className="info">Authors you&apos;ve added:</p>
        <ul>
            {customAuthors.map(author =>
                <AuthorSuggest key={author.id}
                               isActive={false}
                               isAdded={true}
                               addedReferee={author.referee}
                               author={author}/>
            )}
        </ul>
    </div>;
}


export default function SearchAuthors(
    { docKind, docId, additionalDocKind=null, additionalDocId=null, settings, setCustomAuthorIds, customAuthorIds }
) {
    const [total, setTotal] = React.useState(0);
    const [suggests, setSuggests] = React.useState([]);
    const [active, setActive] = React.useState(null);

    let key = null;

    if (additionalDocKind && additionalDocId) {
        [key,] = makeCustomRefereesWithAdditionalDocKey(
            docKind, docId, additionalDocKind, additionalDocId, customAuthorIds);
    } else {
        [key,] = makeCustomRefereesKey(docKind, docId, customAuthorIds);
    }

    const [reqstate, setReqstate] = React.useState({});
    React.useEffect(() => {

        if (!customAuthorIds?.length) {
            return;
        }

        const allQsSettings = helpers.settingsToQSDefined({
            authorsIds: customAuthorIds,
            ...settings,
        });

        let url = `/api/referees/${docKind}/${docId}`;
        if (additionalDocKind && additionalDocId) {
            url = `${url}/${additionalDocKind}/${additionalDocId}`;
        }
        url = `${url}/custom`;

        setReqstate({loading: true});
        sendRequest(url, {
            method: 'POST',
            // to override json content-type in sendRequest
            headers: { 'Content-type': 'application/x-www-form-urlencoded' },
            body: allQsSettings,
        }).then(response => {
            if (response.ok) {
                updateGlobalData((store, { value: { referees, scoredPanelMembers }}) => {
                    if (docKind !== 'panel') {
                        store[key] = enrichReferees(referees);
                    } else {
                        store[key] = scoredPanelMembers;
                    }
                }, response);
                setReqstate({loading: false, ok: true});
            }
        });
    }, [docKind, docId, additionalDocKind, additionalDocId, settings, customAuthorIds, key]);

    const customReferees = useSubscription(key) || [];

    const customRefereesAsAuthors = React.useMemo(() => customReferees.map(r => ({
        extra: {
            articlesCount: r.articlesCount,
            hIndex: r.hIndex,
        },
        id: r.author.id,
        label: `${r.author.name} (${r.articlesCount} ${singlePlural('article', r.articlesCount)})`,
        type: 'author',
        value: r.author.name,
        referee: r
    })), [customReferees]);

    const requestCache = React.useRef(new LRUCache());
    const suggestFetch = React.useCallback((query: string): Promise<SuggestsResponseType> => {
        return fetch(`/api/autocomplete/search-input?type=authors&query=${encodeURIComponent(query)}`, {
            credentials: 'same-origin',
        })
            .then(checkStatus)
            .then(response => response.json());
    }, []);

    const onGetSuggests = (total: number, suggests: Array<SuggestType>) => {
        setTotal(total);
        setSuggests(suggests);
    };

    const handleSelect = (item: SuggestType) => {
        if (typeof item.id === 'number') {
            onGetSuggests(0, []);
            setReqstate({loading: true});
            setCustomAuthorIds(
                [ ...customReferees.map(r => r.author.id), item.id ]
            );
        }
    };

    const handleSuggestFocus = (item: SuggestType) => {
        setActive(item.id);
    };

    const createHandleSelect = (item: SuggestType) => {
        return () => { handleSelect(item); };
    };

    return <div className="panel panel-default search-block search-authors-block">
        <div className="top search-header panel-title">
            <div className="search-entity">
                {reqstate.ok === false && <Alert bsStyle="danger">
                    {reqstate.value && reqstate.value.error ?
                        reqstate.value.error
                        : `An unexpected error has occured: ${reqstate.statusText}`
                    }
                 </Alert>}
                <SuggestBox minChars={2}
                            maxLabelLength={70}
                            placeholder="Enter any name (email, ORCID, Prophy link) to add to the comparison. Name format: John Smith, John Z Smith"
                            callback={handleSelect}
                            suggestFetch={suggestFetch}
                            divClasses="author-suggest"
                            onSuggestFocus={handleSuggestFocus}
                            onGetSuggests={onGetSuggests}
                            requestCache={requestCache.current}
                            showSuggestType={false}
                            selectOnExactMatch={true} />
            </div>
        </div>
        <div className="search-entity results">
            {!!suggests.length && <>
                <p className="info">
                    Showing {suggests.length}{' '}
                    {singlePlural('author', suggests.length)}{' '}
                    {suggests.length < total && <>
                        of {total} found.<br/>
                        <span className="help-block">
                            If there are too many results, try entering a more{' '}
                            specific name (e.g. add middle names).
                        </span>
                    </>}
                </p>
            </>}
            <ul>
                {suggests.map((a: SuggestType) => {
                    const addedReferee = customReferees && customReferees.find(r => r.author.id === a.id);

                    return <AuthorSuggest
                        key={a.id}
                        isActive={active === a.id}
                        isAdded={addedReferee || customReferees.some(ca => ca.id === a.id)}
                        addedReferee={addedReferee}
                        onClick={createHandleSelect(a)}
                        author={a} />
                })}
            </ul>
        </div>
        {!!customAuthorIds && customAuthorIds.length > 0 && <>
            {customReferees?.length ?
                <AddedAuthors customAuthors={customRefereesAsAuthors} />
                : <div className="search-entity results">
                    <p className="info"><span className="help-block">Loading custom referees…</span></p>
                </div>}
        </>}
    </div>;
}
