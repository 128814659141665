import React, { Fragment } from 'react';

import DocumentTitle from 'react-document-title';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';

import {getCookieFlag, setCookieFlag, COOKIE_RF_TOP_HELP_COLLAPSED} from '../Cookies';
import type { ProposalType } from './Types';
import { ProposalLink } from '../proposals/Links';
import GroupedConcepts from '../proposals/GroupedConcepts';
import Collapsible from '../widgets/Collapsible';
import { interpose } from '../utils';
import { AuthorList } from '../articles/Authors';


function AttributesList({attributes}) {
    const attributeElems = attributes.map(attr => <span key={attr.label}>
        {attr.label}:&nbsp;<strong>{attr.value}</strong></span>
    );

    return <div>
        {interpose(attributeElems, ', ')}
    </div>;
}


export default class RefereedProposal extends React.PureComponent<
    { proposal: ProposalType },
    {
        collapsed: boolean
    }
> {
    state = {
        collapsed: true,
        helpCollapsed: getCookieFlag(COOKIE_RF_TOP_HELP_COLLAPSED),
    };

    toggleCollapsed = () => {
        this.setState({ collapsed: !this.state.collapsed });
    };

    toggleHelpCollapsed = () => {
        setCookieFlag(COOKIE_RF_TOP_HELP_COLLAPSED, !this.state.helpCollapsed);
        this.setState({ helpCollapsed: !this.state.helpCollapsed });
    };

    render() {
        const { proposal } = this.props;
        const displaySettings = proposal.displaySettings;

        return <div className="proposal-brief">
            <DocumentTitle title={`Referee Finder - ${proposal.title} - Prophy`} />
            <div style={{float: 'right', maxWidth: '50%', marginLeft: '10px'}}>
                <div style={{float: 'right'}} >
                    <button className="btn btn-link btn-xs" onClick={this.toggleHelpCollapsed}>
                        <h4>
                            <Glyphicon
                                glyph={this.state.helpCollapsed ? 'chevron-down' : 'chevron-up'}
                                style={{verticalAlign: 'bottom', marginRight: '3px'}} />
                            What is this page?
                        </h4>
                    </button>
                </div>
                <div style={{clear: 'both'}}
                     className={'alert alert-info ' + (this.state.helpCollapsed ? 'collapse' : '')}>
                    {displaySettings.RF_proposal_top_help_message ?
                        <div dangerouslySetInnerHTML={{ __html: displaySettings.RF_proposal_top_help_message}} />
                        : <>
                            <p>This page provides candidate referee recommendations based on the topics found in the document.</p>
                            <p>Click on the <Glyphicon glyph="cog"/> to adjust filters of seniority, expertise, gender, geographic location, or to add, remove or adjust the scientific concepts used.</p>
                            <p>More information on each suggested referee can be accessed by simply clicking on their names.</p>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <p>For more information regarding this interface please follow <a href="https://blog.prophy.ai/referee-referee-finder-the-ultimate-guide/" target="_blank">this link</a>.</p>
                        </>}
                    <button onClick={this.toggleHelpCollapsed} className="btn btn-link" style={{margin: '5px 0 0', padding: 0, textDecoration: 'underline'}}>Hide this message</button>
                </div>
            </div>

            <h2><ProposalLink {...proposal} /></h2>
            <AuthorList authors={proposal.authors}
                        authorsCount={proposal.authors.length}
                        short />
            <div>Folder: <a href={`/folder/${proposal.folder.id}/`}>{proposal.folder.name}</a></div>
            {!!proposal.origin_id && <div>Origin ID: <strong>{proposal.origin_id}</strong></div>}
            {proposal.attributes && <AttributesList attributes={proposal.attributes} />}

            {!this.state.collapsed &&
                <Fragment>
                    <Collapsible>
                        <div>{proposal.abstract}</div>
                    </Collapsible>
                    <GroupedConcepts groupedConcepts={proposal.groupedConcepts} />
                </Fragment>
            }
            <button className="toggle-visibility toggle" onClick={this.toggleCollapsed}>
                <span>
                    {this.state.collapsed ?
                        `\u2193 Show abstract and concepts \u2193`
                        : `\u2191 Hide abstract and concepts \u2191`
                    }
                </span>
            </button>
        </div>;
    }
}
