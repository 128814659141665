import React from 'react';
import queryString from 'query-string';

import helpers from './helpers';
import type { DocKindType } from './Types';
import LoadingREST from '../LoadingREST';
import ToTheTop from '../widgets/ToTheTop';

import { sendRequest, useTrack } from '../datastore';
import { makeRefDocArticleQuery } from '../articles/ArticleData';
import { makeFullProposalQuery } from '../proposals/ProposalData';
import { makeJobQuery } from '../jobs/JobData';
import { makeAuthorConceptsQuery } from '../authors/AuthorData';
import { SelectedRefereesContextProvider } from './SelectedRefereesContext';
import Referees from './Referees';
import RefereedArticle from './RefereedArticle';
import RefereedAuthor from './RefereedAuthor';
import RefereedJob from './RefereedJob';
import RefereedProposal from './RefereedProposal';
import RefereedSavedSearch from './RefereedSavedSearch';


type RefereesPagePropsType = {
    id: string,
    docKind: DocKindType,
    history: any,
    location: any
};


function getAuthors(locationSearch) {
    let authors = queryString.parse(locationSearch).author;

    if (!authors) {
        authors = [];
    } else if (typeof authors === 'string') {
        authors = [authors];
    }

    return authors.map(id => parseInt(id, 10));
}

function getJobId(docKind, docId, locationSearch) {
    if (docKind === 'vacancy') {
        return docId;
    }
    const forJob = queryString.parse(locationSearch).forJob;
    if (forJob) {
        return parseInt(forJob, 10) || null;
    }
    return null;
}


export function DocumentReferees({ doc, docKind, docId, history, location }) {

    const settingsId = Number(queryString.parse(location.search).settingsId) || null;
    const customAuthorIds = React.useMemo(() => getAuthors(location.search),
                                          [location.search]);
    const forJobId = getJobId(docKind, docId, location.search);

    let concepts = doc.concepts || doc.groupedConcepts || doc.topConcepts;
    // Jobs can be associated with some concepts. Document concepts are in `occurrences`
    if (docKind === 'vacancy') {
        concepts = doc.occurrences;
    }
    if (docKind === 'proposal') {
        concepts = doc.groupedConcepts;
    }

    const docTopics = React.useMemo(() => {
        return docKind === 'proposal' ?
            doc.groupedConcepts.map(g => ({
                title: '',
                concepts: g
            }))
            : [];
    }, [docKind, doc]);

    const setCustomAuthorIds = React.useCallback(authorIds => {
        const params = {
            ...queryString.parse(location.search),
            author: authorIds,
        }

        history.push(
            `/referees-beam/${docKind}/${docId}?${queryString.stringify(params)}`
        );
    }, [location.search, docKind, docId, history]);

    const setSettings = (settings, settingsId) => {
        const customAuthorIds = queryString.stringify({ author: getAuthors(location.search) });
        const params = settingsId ? `settingsId=${settingsId}` : helpers.settingsToQS(settings);

        const qs = [customAuthorIds, params].filter(part => part).join('&');
        history.push(`/referees-beam/${docKind}/${docId}?${qs}`);
    }

    const defaultSettings = React.useMemo(() => helpers.getDefaultSettings(doc, docKind),
                                          [doc, docKind]);
    return <>
        {docKind === 'proposal' &&
         <RefereedProposal proposal={doc} />}
        {docKind === 'article' &&
         <RefereedArticle article={doc} />}
        {docKind === 'vacancy' &&
         <RefereedJob job={doc} />}
        {docKind === 'author' &&
         <RefereedAuthor author={doc} />}
        {docKind === 'saved_search' &&
         <RefereedSavedSearch search={doc} />}
        {(!concepts || concepts.length === 0) && docKind !== 'saved_search' && <>
            <div className="alert alert-warning">
                Looks like this {docKind} contains no concepts, which are required for referees analysis. <br/>
                If this is a mistake, contact us at&nbsp;
                 <a href={`mailto:support@prophy.ai?subject=Empty referees [${docKind}-${docId}]`}>support@prophy.ai</a>
            </div>
        </>}
        <SelectedRefereesContextProvider>
            <Referees
                doc={doc}
                docId={docId}
                docKind={docKind}
                docTopics={docTopics}
                forJobId={forJobId}
                settingsId={settingsId}
                defaultSettings={defaultSettings}
                customAuthorIds={customAuthorIds}
                setCustomAuthorIds={setCustomAuthorIds}
                setSettings={setSettings}
                pageSize={50}
                location={location}
            />
        </SelectedRefereesContextProvider>
        <ToTheTop/>
    </>;
}

function makeSavedSearchQuery(search_id) {
    const key = `saved-search-${search_id}`;
    return {
        id: key,
        get: key,
        set: function (store, { value: { search } }) {
            store[key] = search;
        },
        req: () => sendRequest(
            `/api/saved-search/${search_id}`)
    };
}

export function RefereedDocument(
    { docKind, id, history, location }: RefereesPagePropsType
) {
    // pass default sorts as on main document page to reuse requests
    let query;
    switch (docKind) {
        case 'proposal': query = makeFullProposalQuery(id); break;
        case 'article': query = makeRefDocArticleQuery(id); break;
        case 'vacancy': query = makeJobQuery(id); break;
        case 'author': query = makeAuthorConceptsQuery(id, ''); break;
        case 'saved_search': query = makeSavedSearchQuery(id); break;
        default: console.error(`docKind ${docKind} is not implemented in switch`);
    }

    const [doc, reqstate] = useTrack(query);

    return <LoadingREST reqstate={reqstate} doc={doc} what={docKind}>
        <DocumentReferees
            doc={doc}
            docId={id}
            docKind={docKind}
            history={history}
            location={location}
        />
    </LoadingREST>;
}

export default RefereedDocument;
